import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import {
    T_Asset,
    useAssetsList,
    useCryptohubBlockChainDetailsRequest,
    useCryptohubSettleRequest,
    useCryptohubTransactionRequest,
    useCurrencyList,
    useSettleCryptohubPaymentRequest,
} from "./CryptohubPaymentQueries";
import { CopyToClipboardButton } from "app/components/inputs/buttons";
import Loader from "app/components/unsorted/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useCryptohubPaymentStyles } from "./CryptohubPaymentStyles";
import { IntegrationView } from "../common";
import { useLoader, useNotification } from "app/providers";
import { redirectTo } from "app/utils";
import { ApiConfig } from "app/config";
import { RQ } from "app/hooks";

const TransactionDetailsList = ({transactionDetails} : {transactionDetails: any}) => {
    return <TableBody>
        <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Quantity</TableCell>
        </TableRow>
        {
            transactionDetails.map((walletDetail: any, index: number) => (
                <TableRow key={index}>
                    <TableCell>{walletDetail.block_chain_txHash}</TableCell>
                    <TableCell>{walletDetail.quantity}</TableCell>
                </TableRow>
            )) || null
        }
    </TableBody>
}

const ConfirmDialog = ({isOpen, setIsOpen, transactionId, onSubmit} : {isOpen: boolean, setIsOpen: any, transactionId: String, onSubmit: any}) => {
    const cryptohubBlockChainDetailsRequest = useCryptohubBlockChainDetailsRequest(transactionId);
    const tableData = cryptohubBlockChainDetailsRequest.result || [];
    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Settle Payment"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to settle the payment?
                </DialogContentText>
                <TransactionDetailsList transactionDetails={tableData} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsOpen(false)} color="primary">
                    Disagree
                </Button>
                <Button onClick={() => {
                    setIsOpen(false)
                    onSubmit(tableData)
                }} color="primary" autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export const CryptohubSettlement = () => {
    const { transactionId } = useParams();
    const { classes } = useCryptohubPaymentStyles();
    const settleRequest = useCryptohubSettleRequest(transactionId || "");
    const transactionRequest = useCryptohubTransactionRequest(transactionId || "");
    const loader = useLoader();
    const useSettleCryptohubPaymentRequestQuery = useSettleCryptohubPaymentRequest();
    const notification = useNotification();
    const navigate = useNavigate();
    const [transactionReqId, setTransactionReqId] = useState(null);
    const [open, isOpen] = useState(false); 

    const assetsList = useAssetsList();

    const getAssetSymbol = (asset: string) => {
        let assetSymbol = "";

        if (assetsList.result) {
            const assetObj = assetsList.result.find(
                (assetObj: T_Asset) => assetObj.fireblocks_asset_id === asset
            ) as T_Asset;
            if (assetObj) assetSymbol = assetObj.acts_as;
        }

        return assetSymbol;
    };

    const apiConfig = ApiConfig.create(
        'crypto-hub-transaction', ['crypto-hub-transaction'],
    );

    const transactionPostRequest = RQ.usePostRequest({
        url: apiConfig.url,
        mutationKey: apiConfig.key,
    });

    useEffect(() => {
        transactionPostRequest.EXECUTE({
            payload:{request_uid:transactionId},
            onSuccessFn: (data, message) => {
                setTransactionReqId(data.transaction_id);
            },
            onErrorFn: (data, message) => {
                notification.error(message);
            }
        })
    }, []);

    const handleOnSettlePayment = async (data: any) => {
        loader.show();
        const payload = {
            "pk": `${transactionReqId}`,
            "transactions": [
                ...data
            ]
        };
        await useSettleCryptohubPaymentRequestQuery.EXECUTE({
            payload,
            onSuccessFn: (data, message): void => {
                redirectTo({url: data.redirect_url});
            },
            onErrorFn: (error, message): void => notification.error(message),
        }).finally(() => {
            loader.hide();
        });
    };

    const handleMakePayment = async () => {
        console.log(transactionId);
        navigate(`/cryptohub-payment/${transactionId}`);
    }

    if (assetsList.isResolving || transactionRequest.isResolving || transactionPostRequest.isPending) {
        return <Loader isLoading={true} />;
    }

    return (
        <IntegrationView
            activeComponent={
                <Box className={classes.mainCard}>
                    <ConfirmDialog isOpen={open} setIsOpen={isOpen} transactionId={settleRequest.result?.id} onSubmit={handleOnSettlePayment}/>
                    <Box className={classes.innerCard}>
                        <Box className={classes.midBoxC1}>
                            <Box className={classes.cryptoBox}>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    Total Asset to be sent :{" "}
                                </Typography>
                                <Typography variant="h5">
                                    {settleRequest.result?.amount}
                                </Typography>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    {getAssetSymbol(
                                        transactionRequest.result
                                            .fireblocks_asset_id
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.midBoxC1}>
                            <Box className={classes.cryptoBox}>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    Asset already sent :{" "}
                                </Typography>
                                <Typography variant="h5">
                                    {
                                        settleRequest.result
                                            .received_quantity
                                    }
                                </Typography>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    {getAssetSymbol(
                                        transactionRequest.result
                                            .fireblocks_asset_id
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.midBoxC1}>
                            <Box className={classes.cryptoBox}>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    Remaining Asset to be sent :{" "}
                                </Typography>
                                <Typography variant="h5">
                                    {settleRequest.result?.amount -
                                        settleRequest.result?.received_quantity}
                                </Typography>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    {getAssetSymbol(
                                        transactionRequest.result
                                            .fireblocks_asset_id
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                gap: "10px",
                            }}
                        >
                            <Button
                                className={classes.settlePaymentButton}
                                onClick={() => isOpen(true)}
                            >
                                Settle Payment
                            </Button>
                            <Button
                                className={classes.settlePaymentButton}
                                onClick={handleMakePayment}
                            >
                                Pay
                            </Button>
                        </Box>
                    </Box>
                </Box>
            }
        />
    );
};
