import React, {useEffect, useState} from "react";
import {T_UseCryptohubPaymentState} from "./CryptohubPaymentHooks";
import {Box, Button, IconButton, TableBody, TableCell, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {T_TransactionDetail, TransactionDetailsForm} from "./TransactionDetailsForm"
import Alert from "@mui/material/Alert";
import {useCompleteCryptohubPaymentRequest, useCryptohubBlockChainDetailsRequest, useCryptohubTransactionRequest} from "./CryptohubPaymentQueries";

import {useLoader, useNotification} from "app/common";
import {redirectTo} from "app/utils";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "app/components/unsorted/Loader";

type T_ViewProps = {
    classes: any;
    paymentState: T_UseCryptohubPaymentState;
    transactionId: string | any;
};


type T_TransactionDetailsListProps = {
    transactionDetails: T_TransactionDetail[];
    handleDeleteTransactionDetail: (index: number) => void;
}


const TransactionDetailsList = ({transactionDetails, ...args}: T_TransactionDetailsListProps): React.JSX.Element[] => {
    const { t } = useTranslation();

    if (transactionDetails.length > 0) {
        return transactionDetails.map((walletDetail: T_TransactionDetail, index: number) => (
            <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell>{walletDetail.type}</TableCell>
                <TableCell>{walletDetail.tx_hash}</TableCell>
                <TableCell>{walletDetail.quantity.toFixed(6).replace(/\.?0+$/, "")}</TableCell>
                <TableCell align="right">
                    <IconButton aria-label="delete" color={"error"}
                                onClick={() => args.handleDeleteTransactionDetail(index)}
                                disabled={walletDetail.disabled}
                                >
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>
        ));
    }


    return [0].map(() => (
        <TableRow key={"#noWalletsMessage"} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell colSpan={3}>
                <Alert severity="info">
                    <b>{t("No transaction details are selected yet!")}</b>
                    <br/>
                    {t("Fill up the transaction details below and click on 'Add' button, to add to list.")}
                    <br/> <br/>
                    <i>{t("You need to provide at least 1 transaction detail to proceed further.")}</i>
                    {/*<br/><br/>*/}
                    {/*<Link href={"https://www.binance.com/en-IN/square/post/1094681"} target="_blank">*/}
                    {/*    How do I find last 6 digits of wallet address?*/}
                    {/*</Link>*/}
                </Alert>

            </TableCell>
        </TableRow>
    ))
}


export const TransactionCompletedView = ({classes, paymentState, transactionId}: T_ViewProps): React.JSX.Element => {
    const { t } = useTranslation();
    const [transactionDetails, setTransactionDetails] = useState<T_TransactionDetail[]>([]);
    const completeTransactionRequest = useCompleteCryptohubPaymentRequest();
    const notification = useNotification();
    const loader = useLoader();
    const transactionRequest = useCryptohubTransactionRequest(transactionId);
    const [showReducedPayment, setShowReducedPayment] = useState(false);
    const navigate = useNavigate();
    const cryptohubBlockChainDetailsRequest = useCryptohubBlockChainDetailsRequest((paymentState.transactionLink?.transaction_id || "") as string);


    useEffect(() => {
        if (cryptohubBlockChainDetailsRequest.result) {
            const transactionDetails = cryptohubBlockChainDetailsRequest.result.map((transaction : any) => {
                return {
                    type: "transaction",
                    tx_hash: transaction.block_chain_txHash,
                    quantity: parseFloat(transaction.quantity),
                    disabled: true
                }
            });
            setTransactionDetails(transactionDetails);
        }
    }, [cryptohubBlockChainDetailsRequest.result as boolean]);

    const handleAddTransactionDetail = (tWalletDetail: T_TransactionDetail) => {
        setTransactionDetails(prevAddresses => [...prevAddresses, tWalletDetail]);
    }

    const handleDeleteTransactionDetail = (walletIndex: number) => {
        setTransactionDetails(transactionDetails.slice(0, walletIndex).concat(transactionDetails.slice(walletIndex + 1)));
    }

    const handleOnSubmit = async () => {
        loader.show();
        const payload = {
            "pk": `${paymentState.transactionLink?.transaction_id}` || "",
            "transactions": transactionDetails.map((data) => (
                {
                    [data.type === "source" ? "source_address" : "tx_hash"]: data.tx_hash,
                    quantity: data.quantity.toFixed(6).replace(/\.?0+$/, "")
                }
            ))
        };
        await completeTransactionRequest.EXECUTE({
            payload,
            onSuccessFn: (data, message): void => {
                notification.success(message);
                redirectTo({url: data.redirect_url});
            },
            onErrorFn: (error, message): void => {
                if(transactionRequest.result?.allow_reduced_payment){
                    if(message === 'Transferred funds insufficient.'){
                        setShowReducedPayment(true);
                    }
                    else{
                        notification.error(message);
                    }
                }
                else{
                    notification.error(message);
                }
            },
        }).finally(() => {
            loader.hide();
        });

    };

    if(transactionRequest.isResolving || cryptohubBlockChainDetailsRequest.isResolving){
        return <Loader isLoading={true} />
    }


    return (
        <Box className={classes.mainCard} sx={{minWidth: "680px"}}>
            <Typography gutterBottom variant="h5" component="div" align={"center"}>
                {t("Provide Transaction Details")}
            </Typography>

            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("Type")}</TableCell>
                        <TableCell>{t("Transaction hash / Source Wallet Address")}</TableCell>
                        <TableCell>{t("Asset Quantity")}</TableCell>
                        <TableCell>{t("Action")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TransactionDetailsList {...{transactionDetails, handleDeleteTransactionDetail}} />
                </TableBody>
            </Table>

            <Box className={classes.walletForm}>
                <TransactionDetailsForm onSubmitHandler={handleAddTransactionDetail}/>
            </Box>

            <Button
                className={classes.doneButton} sx={{
                    marginTop: 5,
                }}
                onClick={handleOnSubmit}
                disabled={!transactionDetails.length}
            > 
                {t("Submit")}
            </Button>
            {
                showReducedPayment && 
                <>  
                    <Typography gutterBottom variant="h5" component="div" align={"center"}>
                        Seems like you have transferred insufficient funds.
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div" align={"center"}>
                        If you want to proceed with transferred assets, click on the button below.
                    </Typography>
                    <Button
                        className={classes.doneButton} sx={{marginTop: 1,}}
                        onClick={() => {
                            navigate(`/cryptohub-payment/${transactionId}/settle-payment`)
                        }}
                    > 
                        {t("Settle for less")}
                    </Button>
                </>
            }
        </Box>
    );
};
