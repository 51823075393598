import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {T_StyleClasses} from "app/styles";
import AuthScreenLeftBox from "app/components/unsorted/AuthScreenLeft";
import {Formik} from "formik";
import * as Yup from "yup";
import {useGoogleLogin} from "@react-oauth/google";
import {getAxiosAPI, postAxiosAPI} from "app/utils/axiosApiRequests";
import MessageAlert from "app/components/unsorted/MessageAlert";
import {useNavigate} from "react-router-dom";
import {autenticacaoCall, useAuthenticator} from "app/hooks";
import {useTranslation} from "react-i18next";
import {LanguageDropdown} from "app/components/language_switcher/LanguageDropDown";


const form_schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = (classes: T_StyleClasses) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const authenticator = useAuthenticator();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      console.log(tokenResponse);
      const value = {
        email: "token",
        password: tokenResponse.access_token,
      };
      await handleAPIcall(value, "google");
    },ux_mode: "redirect",
  });

  const AutenticacaoVarification = () => {
      const oAuthCallReturn = autenticacaoCall();
      console.log("OAuthCallReturn ",oAuthCallReturn)
  }

  const handleAPIcall = async (values: any, authType: string) => {
    const endPoint = "/auth/access";
    const payload = {
      auth_type: authType,
      user: values.email,
      password: values.password,
    };
    await postAxiosAPI(endPoint, JSON.stringify(payload))
      .then((res: any) => {
        console.log(res.data);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
          setLoading(false);
        } else {
          localStorage.setItem("access_token", res.data.data.access);
          localStorage.setItem("refresh_token", res.data.data.refresh);
          setSnackbarMessage("Login Success!");
          setShowSnackbar(true);
          checkVerifications();
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Login Failed!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const checkVerifications = async () => {
    await getAxiosAPI("/verification/details", true)
      .then((res: any) => {
        console.log(res.data.data);
        const response = res.data.data;
        localStorage.setItem("userVerifications", JSON.stringify(response));
        setTimeout(() => {
          navigate("/verify-otp");
        }, 100);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
    const { t } = useTranslation();

  return (
    <Grid container className={classes.mainGrid}>
      {/*<Loader isLoading={loading} />*/}
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Grid item md={6} className={classes.leftGrid}>
        <AuthScreenLeftBox
          title={t("welcome")}
          bttomText={t("No account")}
          routeName={t("Sign up")}
          path="/signup"
        />
      </Grid>
      <Grid item md={6} className={classes.rightGrid}>
          <Box sx={{position: "relative",right:"auto"}}>
            <LanguageDropdown/>
        </Box>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={form_schema}
          onSubmit={(values: any) => {
            authenticator.loginWithCredentials(
              values.email,
              values.password,
              "cred"
            );
          }}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
          }: any) => (
            <Box className={classes.formBox}>
              <Typography
                align="center"
                variant="h3"
                className={classes.formHeading}
              >
                  Sign In
              </Typography>
              <TextField
                id="outlined-basic"
                label={t("Email")}
                variant="outlined"
                autoComplete="off"
                className={classes.inputBox}
                value={values.email}
                onBlur={handleBlur(t("email"))}
                onChange={handleChange(t("email"))}
                autoFocus
              />
              {touched.email && errors.email && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {t(errors.email)}
                </Typography>
              )}
              <TextField
                id="outlined-basic"
                label={t("Password")}
                variant="outlined"
                type="password"
                autoComplete="off"
                className={classes.inputBox}
                value={values.password}
                onBlur={handleBlur(t("password"))}
                onChange={handleChange(t("password"))}
              />
              {touched.password && errors.password && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {t(errors.password)}
                </Typography>
              )}
              <Button
                // onClick={() => downloadCSV(data, headings)}
                onClick={() => handleSubmit()}
                variant="contained"
                className={classes.submitBtn}
              >
                  {t("Sign in")}
              </Button>
              {/* <Box className={classes.orBox}> */}
              {/* <Divider /> */}
              <Typography
                align="center"
                variant="h5"
                className={classes.orText}
              >
                or
              </Typography>
              {/* <Divider /> */}
              {/* </Box> */}
              <Box className={classes.bottomBTNBox}>
                <Button
                  startIcon={
                    <Box
                      component="img"
                      className={classes.iconGA}
                      src="assets/logos/google.svg"
                      alt="Descriptive Alt Text"
                    />
                  }
                  onClick={() => authenticator.loginWithGoogle()}
                  className={classes.bottomBtns}
                >
                  Google
                </Button>
                <Button
                  startIcon={
                    <Box
                      component="img"
                      className={classes.iconGA}
                      src="assets/logos/auth1.png"
                      alt="Descriptive Alt Text"
                    />
                  }
                  onClick={AutenticacaoVarification}
                  disabled={false}
                  className={classes.bottomBtns}
                >
                  Autenticação
                </Button>
              </Box>
              <Box className={classes.bottomText}>
                <Typography variant="h5" align="center" color="primary">
                  Don’t have an account?{" "}
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  color="primary"
                  className={classes.routeName}
                  onClick={() => navigate("/signup")}
                >
                  {" "}
                  <b>{t("Sign up")}</b>
                </Typography>
              </Box>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default Login;
