import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {useLocalization} from "../../hooks";

const languages = [
  { code: 'en', name: 'English' },
  { code: 'pt', name: 'Português' },
  { code: 'zh', name: 'Mandarin' },
];

export const LanguageDropdown = () => {
  const { languageCode, initializeLanguage } = useLocalization();

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    initializeLanguage(newLanguage);
  };

  return (
    <FormControl variant="outlined" size={"small"} sx={{position: "relative", right:"auto", left:"70%"}}>
      <InputLabel id="language-select-label">Language</InputLabel>
      <Select
        labelId="language-select-label"
        value={languageCode}
        onChange={handleLanguageChange}
        label="Language"
      >
        {languages.map((lang) => (
          <MenuItem key={lang.code} value={lang.code}>
            {lang.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageDropdown;
