import React from "react";
import {
    DefaultTableComponent,
    T_DefaultTableComponentProps,
    TDF,
} from "app/components/table";
import { pallete } from "app/styles";
import {
    T_WalletDetails,
    useWalletDetailsQuery,
} from "app/queries/UserWalletQueries";
import { Box, IconButton } from "@mui/material";
import { Description, DescriptionRounded, InfoOutlined, InfoRounded, InfoTwoTone } from "@mui/icons-material";
import moment from "moment";

const getStatusColor = (status: string): any => {
    switch (status) {
        case "FAILED":
            return pallete.selections.red;
        case "PENDING":
            return pallete.selections.yellow;
        case "COMPLETED":
            return pallete.selections.green;
        default:
            return pallete.selections.brown;
    }
};

const renderFunction = (data: T_WalletDetails, onTransactionClick: (data: any) => void): React.JSX.Element[] => {

    const transactionStatusColour = getStatusColor(data.status);

    const additionalData = {
        "Source address": data.source_address,
        "Destination Address": data.destination_address,
        "Transaction Hash": data.transaction_hash,
        "Network Fee": data.network_fee,
        "Value (USD)": data.value_USD,
        "Last Updated": data.last_updated,
    };


    return [
        <TDF.TextContent value={data.source} />,
        <TDF.TextContent value={data.destination} />,
        <TDF.NumericContent value={data.amount} />,
        <TDF.StatusBadge
            statusText={data.status}
            color={transactionStatusColour}
        />,
        <TDF.TextContent value={data.initiator} />,
        <TDF.TextContent value={data.date} />,
        <TDF.RawElement
            element={
                    <IconButton
                        onClick={() => {onTransactionClick(additionalData)}}
                    >
                        <InfoOutlined />
                    </IconButton>
            }
        />,
    ];
};

const userWalletTransactionDataMap = {
    Source: "source",
    Destination: "destination",
    Amount: "amount",
    Status: "status",
    Initiator: "initiator",
    Date: "date",
    Description: "description",
};

const userWalletTransactionDataMapWithAdditional = {
    Date: "date",
    "Last Updated": "last_updated",
    Amount: "amount",
    currency: "asset_id",
    Type: "type",
    Status: "status",
    "Network Fee": "network_fee",
    "Value (USD)": "value_USD",
    "Source Address": "source_address",
    "Destination Address": "destination_address",
    "Transaction Hash": "transaction_hash",
    Source: "source",
    Destination: "destination",
    Initiator: "initiator",
};

const getTableData = (data: any) => {
    console.log(data);
    if (!data) {
        return [];
    }
    const deposits = data.deposits?.map((data: any) => ({...data, type: "deposit"})) || [];
    const withdrawals = data.withdrawals?.map((data: any) => ({...data, type: "withdrawals"})) || [];
    const tableDataUnfiltered = [...deposits, ...withdrawals];

    const format = 'Do MMMM YYYY, h:mm A';
    
    const tableData = tableDataUnfiltered.map((transaction: any) => {

        const dateObjectDate = moment(transaction.createdAt).format(format).replace(",", " at");
        const dateObjectLastUpdated = moment(transaction.lastUpdated).format(format).replace(",", " at");

        console.log(dateObjectDate);

        return {
            source: transaction.source.name,
            destination: transaction.destination.name,
            amount: transaction.amount,
            status: transaction.status,
            initiator: transaction.createdBy,
            date: dateObjectDate,
            asset_id: transaction.assetId,
            type: transaction.type,

            source_address: transaction.sourceAddress,
            destination_address: transaction.destinationAddress,
            transaction_hash: transaction.txHash,
            network_fee: transaction.networkFee,
            value_USD: transaction.amountUSD,
            last_updated: dateObjectLastUpdated,
        };
    });
    return tableData;
};

type T_WalletTransactionTableProps = {
    wallet_id: String;
    onTransactionClick: (data: any) => void;
};

const WalletTransactionTable = ({
    wallet_id,
    onTransactionClick,
}: T_WalletTransactionTableProps) => {
    const walletTransactionQuery = useWalletDetailsQuery(wallet_id);

    const headings: string[] = Object.keys(userWalletTransactionDataMap);

    const tableData = getTableData(walletTransactionQuery.result);

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Wallet Transactions",
        headings: headings,
        isLoading: walletTransactionQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: tableData,
        renderFunction: (data: any) => {
            return renderFunction(data, onTransactionClick);
        },
        searchOptions: {
            searchKeys: ["source", "destination", "amount", "status"],
        },
        downloadOptions: {
            fileName: "user_wallet_transactions.csv",
            dataMap: userWalletTransactionDataMapWithAdditional,
        },
    };

    return <DefaultTableComponent {...tableComponentProps} />;
};

export default WalletTransactionTable;
