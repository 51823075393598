import React, {useState} from "react";
import {FORM, validationSchema} from "app/components/inputs";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {Divider} from "@mui/material";
import * as Yup from "yup";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {postAxiosAPI} from "../../../app/utils/axiosApiRequests";
import MessageAlert from "../../../app/components/unsorted/MessageAlert";

Yup.addMethod(Yup.string, "phone_number", function (errorMessage) {
    return this.test("phone_number", errorMessage, (value: any) => {
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber ? phoneNumber.isValid() : false;
    });
});

const schema = Yup
    .object({
        email: validationSchema.email(),
        company: Yup.string().required("Company Name is required"),
        phone_number: Yup.string()
        .min(9,"Phone Number should be at least 9 digits")
        .max(10,"Phone Number should be at most 10 digits")
        .required("Phone number is required"),
    }).required()


interface I_SignUpRequestFormValues {
    email: string
    company: string
    phone_number: number
}

export const SignupRequestForm = (): React.JSX.Element => {
    const onSubmitHandler = (values: any) => {
        //setTimeout(() => console.log("From Submit"), 2000);
        handleAPIcall({values})

    }
    const signUpForm = FORM.useForm({
        name: "Sign Up Request Form",
        validationSchema: schema,
        defaultValues: {email: "", company: "", phone_number: "",},
        onSubmitHandler,
    });
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState<"success" | "error">("success");

     const handleAPIcall = async (values: any) => {
         const endPoint = "/user/account/signup-email";
         const payload = {
             email: values.values.email,
             company: values.values.company,
             phone_number: values.values.phone_number,
         };
         await postAxiosAPI(endPoint, JSON.stringify(payload))
             .then((res: any) => {
                if (res.data.code !== 200) {
                    setSnackbarMessage(res.data.message);
                    setSeverity("error");
                    setShowSnackbar(true);
                } else {
                    setSnackbarMessage("SignUp Request Sent ");
                    setShowSnackbar(true);
                    setSeverity("success");
                }
             })
             .catch((err: any) => {
                 console.log(err);
                setSnackbarMessage("SignUp Request Failed ");
                setShowSnackbar(true);
                setSeverity("error");
             });
    };

    return (<>
        <FORM.Provider form={signUpForm.instance} heading={"Raise a sign up request."}>
            <Alert severity="info">
                <AlertTitle><b>Disclaimer</b></AlertTitle>
                We are currently accepting invitations. Kindly extend yours by completing the form below.
            </Alert>

            <FORM.Input
                {...signUpForm.bind({name: "email", label: "Email Id"})}
                inputOptions={{fullWidth: true}}
            />
            <FORM.Input
                {...signUpForm.bind({name: "company", label: "Company Name"})}
                inputOptions={{fullWidth: true}}
            />

            <FORM.Input
                {...signUpForm.bind({name: "phone_number", label: "Phone Number"})}
                inputOptions={{fullWidth: true, type: "number"}}
            />
            <Divider/>
            {/*import SendOutlinedIcon from '@mui/icons-material/SendOutlined';*/}
            <FORM.SubmitButton
                text={"I am ready to be onboarded"}
                iconName={"Send"}
                formInstance={signUpForm.instance}
                fullWidth={true}
            />
        </FORM.Provider>
        <MessageAlert
            showBar={showSnackbar}
            message={snackbarMessage}
            severity={severity}
            onClose={() => setShowSnackbar(false)}
        />
        </>);
};

