import React from "react";
import { TabContent } from "app/components/tabs";
import { LoginHistoryTable } from "./loginHistoryTable";


const LoginHistory = () => {
    const tabHeading: string = "Login History";
    const resetUrlOnTabChange: boolean = true;

    const tabData = [
        {label: "Login History", component: <LoginHistoryTable />},
    ];

    return TabContent({tabHeading, tabData, resetUrlOnTabChange});
};

export default LoginHistory;
