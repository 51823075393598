import { useEffect, useState } from "react";
import {useAuthManager} from "./auth";

const useInactivityLogout = (timeout: number = 180000) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  const authManager = useAuthManager();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        logout();
      }, timeout);
    };

    const logout = () => {
      authManager.logoutUser(); // Call API
    };

    const handleActivity = () => {
      setIsActive(true);
      resetTimer();
    };

    // Register event listeners
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Start initial timer
    resetTimer();

    return () => {
      // Cleanup listeners and timeout on unmount
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [authManager, timeout]);

  return isActive;
};

export default useInactivityLogout;
